import { Col, Container, Row, Button, Card, Form } from "react-bootstrap";
import { SectionTitle } from "../components/typography";

function ContactPage() {
    return (
        <div className="ContactPage pt-5">
            <section className="my-5" >
                <Container>
                    <SectionTitle className="text-center">Contact Us Now!</SectionTitle>
                    <Card className="border-0 bg-primary-blue">
                        <Card.Body className="p-4">
                            <Row>
                                <Col sm={12} md={12} lg={12} className="mb-3">
                                    <Form>
                                        <Row>
                                            <Col sm={12} md={6} className="mb-3">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="text" placeholder="First Name" />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} className="mb-3">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="text" placeholder="Last Name" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6} className="mb-3">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="email" placeholder="name@example.com" />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={12} md={6} className="mb-3">
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Control type="text" placeholder="Subject" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Control as="textarea" rows={10} placeholder="Write Your Message Here"/>
                                        </Form.Group>
                                        <Button className="w-100 text-center border-1 border-white bg-primary-blue">Send Message</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </section>
            <section className="build-together">
                <Container>
                    <Row>
                        <Col sm={12} md={8} className="mx-auto text-center">
                            <SectionTitle className="text-center">Let's build great things together!</SectionTitle>
                            <div className="content-body text-center">Fill out this form and one of our client success managers will contact you within 24 hours. We have notifications set to make sure your message is received.</div>
                            <Button className="mb-3 primary-blue-btn rounded-2 fw-bold px-5  bg-primary-blue btn btn-primary mt-2">Work with Us</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}

export default ContactPage;